<template>
  <div class="visit-temporary-new-add">
    <van-form @submit="addCustomerReplay()">
      <van-row class="title">客户信息</van-row>
      <div class="baseInfo">
        <van-row type="flex" justify="center" align="center" class="card">
          <van-col span="24">
            <van-field required label="客户：" placeholder="请填写客户"
                       :rules="customerForm.customerName"
                       v-model="data.customerName"></van-field>
            <van-field
                readonly
                required
                clickable
                label="企业类型："
                :rules="customerForm.enterpriseType"
                :value="data.enterpriseType"
                v-model="data.enterpriseType"
                placeholder="请选择企业类型"
                input-align="left"
                @click="onClickEnterpriseType"
            />
            <van-popup v-model="showEnterpriseTypePicker" position="bottom">
              <van-picker
                  ref="picker"
                  show-toolbar
                  :columns="columnsEnterpriseType"
                  @cancel="showEnterpriseTypePicker = false"
                  @confirm="onConfirmEnterpriseType"
                  default-index="0">
              </van-picker>
            </van-popup>
            <van-field
                readonly
                clickable
                required
                label="企业性质："
                :rules="customerForm.enterpriseNature"
                :value="data.enterpriseNature"
                v-model="data.enterpriseNature"
                placeholder="请选择企业性质"
                input-align="left"
                @click="onClickEnterpriseNature"
            />
            <van-popup v-model="showEnterpriseNaturePicker" position="bottom">
              <van-picker
                  ref="picker"
                  show-toolbar
                  :columns="columnsEnterpriseNature"
                  @cancel="showEnterpriseNaturePicker = false"
                  @confirm="onConfirmEnterpriseNature"
                  default-index="0">
              </van-picker>
            </van-popup>
            <van-field
                v-if="data.enterpriseType=='港方'"
                readonly
                clickable
                required
                label="经营性质："
                input-align="left"
                :value="data.businessNature"
                :rules="customerForm.businessNature"
                placeholder="请选择经营性质"
                @click="onClickBusinessNature"
            />
            <van-popup v-model="showBusinessNaturePicker" round position="bottom">
              <van-picker
                  show-toolbar
                  :columns="this.businessNatureItems"
                  @cancel="showBusinessNaturePicker = false"
                  @confirm="onConfirmBusinessNature"
                  default-index="0"
              />
            </van-popup>
          </van-col>
        </van-row>
      </div>
      <van-row class="title">联系人信息</van-row>
      <div class="conInfo">
        <van-row>
          <van-col span="12">
            <div>
              <van-field v-model="data.contacts" label="姓名 :"/>
            </div>
          </van-col>
          <van-col span="12">
            <div>
              <van-field v-model="data.contactsPost" label="职务 ："/>
            </div>
          </van-col>
        </van-row>
        <van-row>
          <div>
            <van-field span="24" v-model="data.contactsPhone" label="电话 :"/>
          </div>
        </van-row>
        <van-row>
          <div>
            <van-field span="24" v-model="data.addressDetailInfo.addressDetailed" label="地址" @click="showMap"/>
            <van-field span="24" v-model="data.addressDetailInfo.addressLongitude" v-show="false" label="经度 :"
                       type="number"/>
            <van-field span="24" v-model="data.addressDetailInfo.addressLatitude" v-show="false" label="纬度 :"
                       type="number"/>
          </div>
        </van-row>
      </div>
      <div style="background-color: #FFFFFF">
        <van-row>
          <van-col span="22">
            <van-field
                required
                label="走访名称："
                :value="data.titleName"
                :rules="customerForm.titleName"
                v-model="data.titleName"
                placeholder="关联本次计划 / 手动填写"
                input-align="left"
                @click="showTitleName = edit"
                label-width="6.5em"
            />
          </van-col>
          <van-col span="2" style="padding:10px 0">
            <i class="el-icon-edit" @click="editTrue" :style="{color: !edit ? '#efb918' : 'black'}"></i>
          </van-col>
        </van-row>
        <van-popup v-model="showTitleName" round position="bottom">
          <van-field
              placeholder="在这里查询走访名称"
              v-model="selTitleNameValue"
              left-icon="search"
          />
          <van-picker
              ref="picker"
              show-toolbar
              :columns="options1"
              @cancel="showTitleName = false,selTitleNameValue=''"
              @confirm="onConfirmTitleName"
              default-index="0">
          </van-picker>
        </van-popup>
        <van-row style="background: #FFFFFF">
          <van-col span="24">
            <van-field
                required
                label="拜访类型："
                :value="data.travelType"
                :rules="customerForm.travelType"
                v-model="data.travelType"
                placeholder="请补全拜访类型"
                input-align="left"
                readonly
                @click="readonlys?showTravelType = true:showTravelType = false"
                label-width="6.5em"
            />
          </van-col>
        </van-row>
        <van-popup v-model="showTravelType" round position="bottom">
          <van-picker
              ref="picker"
              show-toolbar
              :columns="columnsVisitType"
              @cancel="showTravelType = false,selTravelTypeValue=''"
              @confirm="onConfirmTravelType"
              default-index="0">
          </van-picker>
        </van-popup>
        <van-row>
          <van-field
              readonly
              required
              clickable
              label="走访类型："
              input-align="left"
              :value="data.interviewType"
              :rules="customerForm.interviewType"
              placeholder="选择走访类型"
              @click="onClickType"
              label-width="6.5em"
          />
          <van-popup v-model="showInterviewTypePicker" round position="bottom">
            <van-picker
                show-toolbar
                :columns="interviewType"
                @cancel="showInterviewTypePicker = false"
                @confirm="onConfirmType"
                default-index="0"
            />
          </van-popup>
        </van-row>
        <van-field required autosize type="textarea" label="目标及方案" placeholder="目标及方案" :rules="customerForm.goalPlan"
                   v-model="data.goalPlan === 'null'?'':data.goalPlan" label-width="6.5em"></van-field>
        <van-field required label="实际联系人" :rules="customerForm.realContact" placeholder="实际联系人"
                   v-model="data.realContact === 'null'?'':data.realContact" label-width="6.5em"></van-field>
        <van-field required label="业务推进成果" :rules="customerForm.pushResult" placeholder="请填写业务推进成果"
                   v-model="data.pushResult === 'null'?'':data.pushResult" label-width="6.5em"></van-field>
        <van-field v-if="data.comOperate" required label="综合经营情况" :rules="customerForm.comOperate" placeholder="业务量、应收情况、人员等方面"
                   v-model="data.comOperate === 'null'?'':data.comOperate" label-width="6.5em"></van-field>
        <van-field label="综合经营情况" :readonly="true" label-width="6.5em"></van-field>
        <van-field required label="业务量" placeholder="业务量" :rules="customerForm.businessVolume"
                   @click-input="clickItem(data.businessVolume, '业务量', false)"
                   v-model="data.businessVolume === 'null'?'':data.businessVolume" label-width="6.5em" class="lable"></van-field>
        <van-field required label="盈利情况" placeholder="应收情况" :rules="customerForm.profitSituation"
                   @click-input="clickItem(data.profitSituation, '盈利情况', false)"
                   v-model="data.profitSituation === 'null'?'':data.profitSituation" label-width="6.5em" class="lable"></van-field>
        <van-field label="其他" placeholder="人员等方面"
                   @click-input="clickItem(data.others, '其他', false)"
                   v-model="data.others === 'null'?'':data.others" label-width="6.5em" class="lable"></van-field>

        <div v-if="(data.enterpriseType == '船方' && data.enterpriseNature == '船东')">
          <van-row>
            <van-col span="16">
              <van-field label="船舶运营情况" :readonly="true" label-width="6.5em"></van-field>
            </van-col>
            <van-col span="8" style="color: #289AE1; font-size: 14px; padding-top: 8px">
              <span  @click="showModal = true">新建船舶＋</span>
            </van-col>
          </van-row>
          <van-row v-for="(item,index) in listCf">
            <van-col span="21">
              <van-field required  :label="item.shipName" placeholder="请填写船舶运营情况"
                         :rules="customerForm.shipOperation"
                         @click-input="clickItem(item.shipOperation, item.shipName, false)"
                         v-model="item.shipOperation === 'null'?'':item.shipOperation" label-width="6.5em" class="lable"></van-field>
            </van-col>
            <van-col span="3" style="margin-top: 15px" @click="deleteOne(item)">
              <van-icon :name="require('../../../assets/删减.png')"/>
            </van-col>
          </van-row>
        </div>

        <!--填写船舶信息-->
        <div>
          <Modal :visible="showModal" @close="showModal = false" ref="taskModal"
                 id="taskModal1">
            <van-form @submit="saveVessel()">
              <van-field required label="船舶公司：" readonly placeholder="请输入船舶公司" label-width="6.5em"
                         v-model="shipInfo.vesselCompany"></van-field>
              <van-field required label="船舶名称：" placeholder="请输入船名" label-width="6.5em" :rules="customerForm.vesselName"
                         v-model="shipInfo.vesselName"></van-field>
              <van-field label="租船人：" placeholder="请填写租船人" label-width="6.5em"
                         v-model="shipInfo.charterer"></van-field>
              <van-field label="船东信息：" placeholder="请填写船东信息" label-width="6.5em"
                         v-model="shipInfo.customerName"></van-field>
              <van-field label="船东联系人：" placeholder="请填写船东联系人" label-width="6.5em"
                         v-model="shipInfo.contacts"></van-field>
              <van-field label="载重吨：" placeholder="请填写数字" label-width="6.5em" type="number"
                         v-model="shipInfo.dwt"></van-field>
              <van-field label="船长：" placeholder="请填写数字" label-width="6.5em" type="number"
                         v-model="shipInfo.length"></van-field>
              <van-field label="满载吃水：" placeholder="请填写数字" label-width="6.5em" type="number"
                         v-model="shipInfo.draught"></van-field>
              <van-field label="舱口数：" placeholder="请填写数字" label-width="6.5em" type="number"
                         v-model="shipInfo.hatch"></van-field>
              <van-field label="建造年限：" placeholder="请填写建造年限" label-width="6.5em"
                         v-model="shipInfo.period"></van-field>
              <van-field label="主要航线：" placeholder="请填写主要航线" label-width="6.5em"
                         v-model="shipInfo.mainRoutes"></van-field>
              <van-field  label="船舶种类：" placeholder="请填写船舶种类" label-width="6.5em"
                          v-model="shipInfo.vesselType"></van-field>
              <van-field label="主要货种：" placeholder="请填写主要货种" label-width="6.5em"
                         v-model="shipInfo.mainGoods"></van-field>
              <!--              <van-field label="总舱容：" placeholder="请填写总舱容" label-width="6.5em"-->
              <!--                         v-model="shipInfo.toalCapacity"></van-field>-->
              <div class="submit">
                <van-button type="default" native-type="button"
                            @click="showModal=false, vesselInfo ={}">取消
                </van-button>
                <van-button type="info" native-type="submit">确定</van-button>
              </div>
            </van-form>
          </Modal>

        </div>

        <van-row>
          <van-col span="20">
            <van-field required type="textarea" label="遗留问题" :rules="customerForm.problerms" placeholder="遗留问题"
                       :readonly=checked
                       v-model="data.problerms" label-width="6.5em">
            </van-field>
          </van-col>
          <van-col span="4">
            <van-col offset="1" span="23" class="editOrNot">
              填/不填
            </van-col>
            <van-switch :value="checked" @input="onInput" size="20px" style="text-align:center;margin: 0px auto;"/>
          </van-col>
        </van-row>
        <van-row>
          <van-collapse v-model="activeNames" style="text-align: left">
            <van-collapse-item title="选择新增客户(非必选)" name="1">建议保存后添加客户，以免所填信息遗失
              <template #right-icon>
                <van-switch :value="check" @click="clickInput" size="20px" style="text-align:center;margin: 0px auto;"/>
              </template>
            </van-collapse-item>
          </van-collapse>
        </van-row>
        <van-field type="textarea" label="备注" placeholder="备注" v-model="data.remarks" label-width="6.5em"></van-field>
        <van-button type="primary" size="large" native-type="submit">保存
        </van-button>
      </div>
    </van-form>
    <dialog-card :data="DialogData" :fieldValue="fieldValue" :show="show" @success="success" @editVal="editVal"></dialog-card>
    <!-- 百度地图位置选择 -->
    <BMapAddressSelect :addressDetailInfo="this.data.addressDetailInfo" ref="bmapAddressSelect"
                       @confirmMapAddress="confirmMapAddress"></BMapAddressSelect>

    <crm-tab-bar :tabpage="2"/>
  </div>
</template>

<script>
import BMapAddressSelect from "@/components/BMapAddressSelect/index";
import CrmTabBar from "@/components/CrmTabBar";
import {Collapse, CollapseItem, Toast} from 'vant';
import DialogCard from "../../../components/DigitalCell/DialogCard";
import {saveBulkShipinfotwo} from "../../../api/shipInfo";
import Modal from './../../../components/Modal/MyCustomerModal';

export default {
  name: "BulkCrmsVisitTemporaryNewAdd",
  components: {CrmTabBar, BMapAddressSelect, Collapse, CollapseItem, DialogCard, Modal},
  data() {
    return {
      shipInfo: {
        vesselCompany: '',
        vesselName: '',
        charterer: this.$store.getters.name,
        customerName: '',
        contacts: '',
        cooperationTimes: '',
        dwt: '',
        length: '',
        draught: '',
        hatch: '',
        period: '',
        mainRoutes: '',
        vesselType: '散杂货',
        mainGoods: '',
        toalCapacity: '',
        capacity: '',
      },
      show: false,
      fieldValue: '',
      DialogData: {},
      edit: true,
      checked: false,
      options1: [],
      showCompanyName: false,
      showTitleName: false,
      showTravelType: false,
      selCustomerValue: '',
      selTitleNameValue: '',
      showEnterpriseNaturePicker: false,
      showBusinessNaturePicker: false,
      columnsVisitType: [
        {
          value: '',
          text: ''
        },
        {
          value: '001',
          text: '走访拜访'
        }, {
          value: '002',
          text: '电话回访'
        }, {
          value: '004',
          text: '客户来访'
        },
      ],
      columnsEnterpriseType: [
        {
          value: '货方',
          text: '货方'
        }, {
          value: '船方',
          text: '船方'
        }, {
          value: '港方',
          text: '港方'
        }, {
          value: '其他',
          text: '其他'
        }
      ],
      businessNatureItems: [
        '国企', '民营'
      ],
      showEnterpriseTypePicker: false,
      columnsEnterpriseNature: [],
      showPicker: false,
      showPicker1: false,
      maxDate: new Date(2030, 1, 1),
      currentDate: new Date(),
      currentDate1: new Date(),
      readonlys: false,
      data: {
        customerName: '',
        titleName: '',
        enterpriseNature: '',
        businessNature: '',
        travelType: '',
        contactsPost: '',
        contacts: '',
        contactsPhone: '',
        addressDetailInfo: {
          addressDetailed: '',
          addressLongitude: '',
          addressLatitude: '',
          province: '',
          city: '',
          district: ''
        },
        plandateStart: '',
        plandateEnd: '',
        target: '',
        situation: '',
        plan: '',
        interviewType: '',
        goalPlan: '',
        result: '',
        problerms: '',
        remarks: '',
        pushResult: '',
        comOperate: '',
        businessVolume: '',
        profitSituation: '',
        others: ''
      },
      options: [],
      value: [],
      list: [],
      listCf: [],
      showModal: false,
      loading: false,
      customerForm: {
        customerName: [
          {required: true, message: '', trigger: 'blur'},
        ],
        enterpriseType: [
          {required: true, message: '', trigger: 'blur'},
        ],
        enterpriseNature: [
          {required: true, message: '', trigger: 'blur'},
        ],
        businessNature: [
          {required: true, message: '', trigger: 'blur'},
        ],
        travelType: [
          {required: true, message: '', trigger: 'blur'},
        ],
        target: [
          {required: true, message: '', trigger: 'blur'},
        ],
        situation: [
          {required: true, message: '', trigger: 'blur'},
        ],
        plan: [
          {required: true, message: '', trigger: 'blur'},
        ],
        result: [
          {required: true, message: '', trigger: 'blur'},
        ],
        problerms: [
          {required: true, message: '', trigger: 'blur'},
        ],
        titleName: [
          {required: true, message: '', trigger: 'blur'},
        ],
        plandateStart: [
          {required: true, message: '', trigger: 'blur'},
        ],
        plandateEnd: [
          {required: true, message: '', trigger: 'blur'},
        ],
        realContact: [
          {required: true, message: '', trigger: 'blur'},
        ],
        pushResult: [
          {required: true, message: '', trigger: 'blur'},
        ],
        comOperate: [
          {required: true, message: '', trigger: 'blur'},
        ],
        realOperate: [
          {required: true, message: '', trigger: 'blur'},
        ],
        otherDemand: [
          {required: true, message: '', trigger: 'blur'},
        ],
        interviewType: [
          {required: true, message: '', trigger: 'blur'},
        ],
        goalPlan: [
          {required: true, message: '', trigger: 'blur'},
        ],
        businessVolume: [
          {required: true, message: '', trigger: 'blur'},
        ],
        profitSituation: [
          {required: true, message: '', trigger: 'blur'},
        ],
        others: [
          {required: true, message: '', trigger: 'blur'},
        ],
        vesselName: [
          {required: true, message: '', trigger: 'blur'},
        ],
        shipOperation: [
          {required: true, message: '', trigger: 'blur'},
        ],
      },
      activeNames: [],
      check: false,
      interviewType: [
        '业务类拜访', '综合经营情况拜访'
      ],
      showInterviewTypePicker: false,
    }
  },
  mounted() {
    this.init()
    this.checked = false
  },
  watch: {
    'data.customerName': function () {
      this.shipInfo.vesselCompany = this.data.customerName
    }
  },
  methods: {
    init() {
      this.$ajax.post('/api/ajax/visitTemporary/getBulkCrmReplayTitleName.json',
          {
            createdPeople: this.$store.getters.userId
          }).then(res => {
        if (res.status == 200) {
          if (res.data.length > 0) {
            this.columns1 = res.data;
            for (let i = 0; i < res.data.length; i++) {
              var array = {}
              array.text = res.data[i].titleName
              array.value = res.data[i].titleId
              this.options1.push(array)
            }
            console.log(this.options1)
          }
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('初始化失败！');
      })
    },
    to(to) {
      this.$router.push(to)
    },
    addCustomerReplay(formName) {
      this.$ajax.post('/api/ajax/visitTemporary/saveBulkCrmReplayCustomer.json',
          {
            customerName: this.data.customerName,
            enterpriseType: this.data.enterpriseType,
            enterpriseNature: this.data.enterpriseNature,
            remarks: this.data.remarks,
            contactsPhone: this.data.contactsPhone,
            contacts: this.data.contacts,
            contactsPost: this.data.contactsPost,
            addressDetailed: this.data.addressDetailInfo.addressDetailed,
            addressProvince: this.data.addressDetailInfo.addressProvince,
            addressCity: this.data.addressDetailInfo.addressCity,
            addressLongitude: this.data.addressDetailInfo.addressLongitude,
            addressLatitude: this.data.addressDetailInfo.addressLatitude,
            customerGm: this.$store.getters.userId,
            createdPeople: this.$store.getters.userId,
            plandateStart: this.data.plandateStart,
            plandateEnd: this.data.plandateEnd,
            titleId: this.data.titleId,
            crmCustomerid: this.data.crmCustomerid,
            titleName: this.data.titleName,
            travelType: this.data.travelType,
            tempraryVisit: '002',
            target: this.data.target,
            situation: this.data.situation,
            plan: this.data.plan,
            interviewType: this.data.interviewType,
            goalPlan: this.data.goalPlan,
            result: this.data.result,
            problerms: this.data.problerms,
            realContact: this.data.realContact,
            realOperate: this.data.realOperate,
            otherDemand: this.data.otherDemand,
            businessNature: this.data.businessNature,
            pushResult: this.data.pushResult,
            comOperate: this.data.comOperate,
            businessVolume: this.data.businessVolume,
            profitSituation: this.data.profitSituation,
            others: this.data.others,
            bulkCrmCfDetailDTO: this.listCf
          }).then(res => {
        if (res.status == 200) {
          this.$message({
            showClose: true,
            message: '添加成功',
            type: 'success'
          });
          sessionStorage.clear();
          setTimeout(() => {
            if (this.check) {
              this.$router.replace({path: '/CustomerListAdd/' + 'temp' + Math.random().toString(36).slice(2)})
            } else {
              this.$router.push({path: '/BulkCrmsVisitTemporaryExistList/'})
            }
          }, 200);
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('添加失败');
      })
    },
    formatter(time) {
      let nowdata = time == null ? new Date() : new Date(time);
      let year = nowdata.getFullYear();
      let month = nowdata.getMonth() + 1;
      let date = nowdata.getDate();
      let nowtime = year + "-";
      if (month < 10) {
        nowtime += "0";
      }
      nowtime += month + "-";
      if (date < 10) {
        nowtime += "0";
      }
      nowtime += date;

      return nowtime;
    },
    onConfirm(time) {
      this.data.plandateStart = this.formatter(time);
      this.showPicker = false;
    },
    onConfirm1(time) {
      this.data.plandateEnd = this.formatter(time);
      this.showPicker1 = false;
    },
    onClickEnterpriseNature() {
      this.showEnterpriseNaturePicker = true
    },
    onConfirmEnterpriseNature(picker, value) {
      this.data.enterpriseNature = picker.text
      this.showEnterpriseNaturePicker = false
    },
    onClickEnterpriseType() {
      this.showEnterpriseTypePicker = true
      this.data.businessNature = ''
    },
    onConfirmEnterpriseType(picker, value) {
      this.data.enterpriseNature = ''
      this.data.enterpriseType = picker.text
      switch (picker.text) {
        case '货方':
          this.columnsEnterpriseNature = [{
            value: '生产型',
            text: '生产型'
          }, {
            value: '贸易型',
            text: '贸易型'
          }, {
            value: '同行业',
            text: '同行业'
          },]
          break;
        case '船方':
          this.columnsEnterpriseNature = [{
            value: '船东',
            text: '船东'
          }, {
            value: '船东代理',
            text: '船东代理'
          }, {
            value: '其他',
            text: '其他'
          },]
          break;
        case '港方':
          this.columnsEnterpriseNature = [{
            value: '港口',
            text: '港口'
          }, {
            value: '港口代理',
            text: '港口代理'
          }, {
            value: '其他',
            text: '其他'
          },]
          break;
        default:
          this.columnsEnterpriseNature = [{
            value: '其他',
            text: '其他'
          },]
          break;
      }
      this.showEnterpriseTypePicker = false
    },
    onClickBusinessNature() {
      this.showBusinessNaturePicker = true
    },
    onConfirmBusinessNature(picker, value, index) {
      console.log(picker, value, index);
      this.data.businessNature = picker;
      this.showBusinessNaturePicker = false;
    },
    /** 显示地图 */
    showMap() {
      this.$refs.bmapAddressSelect.show();
    },
    /** 确认地图地址 */
    confirmMapAddress(addressDetailInfo) {
      this.data.addressDetailInfo = addressDetailInfo;
    },
    editTrue() {
      this.edit = !this.edit
      this.data.titleId = this.$route.params.name
      this.readonlys = !this.readonlys
      if (this.readonlys) {
        this.data.travelType = ''
      }
    },
    onConfirmTitleName(picker, value) {
      console.log(picker, value);
      this.data.titleName = picker.text;
      this.showTitleName = false;
      this.getTitleNameData(picker)
    },
    onConfirmTravelType(picker, value) {
      console.log(picker, value);
      this.data.travelType = picker.text;
      this.showTravelType = false;
    },
    getTitleNameData(picker) {
      this.data.titleId = picker.value
      this.$ajax.post('/api/ajax/visitTemporary/getBulkCrmReplayTitleInfo.json',
          {
            titleId: picker.value
          }).then(res => {
        if (res.status == 200) {
          if (res.data.length > 0) {
            this.data.plandateStart = res.data[0].plandateStart
            this.data.plandateEnd = res.data[0].plandateEnd
            this.data.travelType = res.data[0].travelType
          }
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('初始化失败！');
      })
    },
    onInput(checked) {
      console.log(checked)
      this.checked = checked;
      if (this.checked) {
        this.data.problerms = '无'
      } else {
        this.data.problerms = ''
      }
    },
    clickInput() {
      this.check = !this.check;
    },
    onClickType() {
      this.showInterviewTypePicker = true
      this.data.interviewType = ''
    },
    onConfirmType(picker, value, index) {
      console.log(picker, value, index);
      this.data.interviewType = picker;
      this.showInterviewTypePicker = false;
    },
    clickItem(val, text, bool){
      this.DialogData.text = text
      this.DialogData.value = val
      this.DialogData.edit = bool
      this.fieldValue = val
      this.show = true
    },
    success(res){
      this.show = res
    },
    editVal(val){
      if(val.text == '业务推进成果'){
        this.data.pushResult = val.value
      }else if(val.text == '综合经营情况'){
        this.data.comOperate = val.value
      }else if(val.text == '业务量'){
        this.data.businessVolume = val.value
      }else if(val.text == '盈利情况'){
        this.data.profitSituation = val.value
      }else if(val.text == '其他'){
        this.data.others = val.value
      }else {
        if (this.listCf.length > 0) {
          for (let i = 0; i < this.listCf.length; i++) {
            if (val.text == this.listCf[i].shipName) {
              this.listCf[i].shipOperation = val.value
            }
          }
        }
      }
    },
    deleteOne(e){
      console.log(e)
      if(this.listCf.length==1){
        this.$toast({
          message: '至少保留一条船舶！',
          position: 'top'
        });
        return
      }
      let index = this.listCf.findIndex(item => {
        if (item.shipName == e.shipName) {
          return true
        }
      })
      this.listCf.splice(index, 1)
    },

    saveVessel(){
      saveBulkShipinfotwo({
        shipInformationid: '',
        vesselName: this.shipInfo.vesselName,
        charterer: this.shipInfo.charterer,
        vesselCompany: this.shipInfo.vesselCompany,
        cooperationTimes: this.shipInfo.cooperationTimes,
        dwt: this.shipInfo.dwt,
        length: this.shipInfo.length,
        draught: this.shipInfo.draught,
        hatch: this.shipInfo.hatch,
        period: this.shipInfo.period,
        mainRoutes: this.shipInfo.mainRoutes,
        vesselType: this.shipInfo.vesselType,
        mainGoods: this.shipInfo.mainGoods,
        toalCapacity: this.shipInfo.toalCapacity,
        capacity: this.shipInfo.capacity,
        createdPeople: this.$store.getters.userId,
      }).then(res => {
        let array={}
        array.shipName=this.shipInfo.vesselName
        array.shipOperation = ''
        array.titleId=this.$route.params.id
        array.replayId=''
        this.listCf.push(array)
        this.clearShip()
        Toast.success('添加船舶成功！');
      }).catch(err => {
        console.log(err)
      });
    },
    clearShip(){
      this.showModal = false
      this.shipInfo.vesselName = ''
      this.shipInfo.customerName = ''
      this.shipInfo.contacts = ''
      this.shipInfo.cooperationTimes = ''
      this.shipInfo.dwt = ''
      this.shipInfo.length = ''
      this.shipInfo.draught = ''
      this.shipInfo.hatch = ''
      this.shipInfo.period = ''
      this.shipInfo.mainRoutes = ''
      this.shipInfo.mainGoods = ''
      this.shipInfo.toalCapacity = ''
      this.shipInfo.capacity = ''
    },
  }
}
</script>

<style scoped>

.visit-temporary-new-add{
  background-color: #FFFFFF;
}

.visit-temporary-new-add .lable{
  margin-left: 15px;
  font-size: 12px;
}

.visit-temporary-new-add {
  overflow-y: scroll;
  height: 2500px;
}

.visit-temporary-new-add .card {
  margin: 10px 5px;
  background: #318EF5;
  border-radius: 6px;
  box-shadow: 6px 6px 5px #888888;
}

.visit-temporary-new-add .title {
  background: #318EF5;
  padding: 10px 5px;
  font-weight: 400;
  font-size: 10px;
  color: #EEEEEE;
  font-style: normal;
  letter-spacing: 0;
  line-height: 15px;
  text-decoration: none;
  text-align: left;
}

.visit-temporary-new-add .conInfo {
  margin: 10px 5px;
  padding: 5px;
  border-radius: 6px;
  background: white;
  box-shadow: 6px 6px 5px #888888;
}

.visit-temporary-new-add .editOrNot {
  text-align: left;
  padding: 10px 5px;
  font-weight: 400;
  font-size: 14px;
  color: #EEEEEE;
  font-style: normal;
  letter-spacing: 0;
  line-height: 19px;
  text-decoration: none;
}

.submit {
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin: 20px 0;
}

.submit .van-button {
  width: 150px;
}
</style>

<style>
.visit-temporary-new-add .van-collapse-item__content {
  padding: 12px 16px;
  color: red;
  font-size: 14px;
  line-height: 1.5;
  background-color: #fff;
}

.visit-temporary-new-add .baseInfo .van-field__control--right {
  color: #5CA4F4;
  font-size: 13px;
}

.visit-temporary-new-add .baseInfo .van-field, .hlnx .van-field, .jbxx .van-field {
  padding-top: 3px;
  height: 30px;
  font-size: 12px;
}

.visit-temporary-new-add .cusInfo .van-field, .qtyPrf .van-field, .conInfo .van-field {
  padding-top: 3px;
  height: 30px;
  font-size: 12px;
}
</style>